<template>
  <LayoutDefault>
    <Loading :active="loading" />

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <Toast
        :message="toastMessage"
        v-model:active="toastActive"
        :type="toastType"
      />
      <!-- Content Header (Page header) -->
      <div class="content-header mt-2">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-xs-12 col-sm-6 mb-3 mb-md-0">
              <router-link :to="{ name: 'Faqs' }" class="custom-back m-0 ttU">
                <i class="fas fa-arrow-circle-left"></i> Atrás
              </router-link>
            </div>
            <!-- /.col -->
          </div>
          <div class="row mb-2">
            <div class="col-xs-12 col-sm-6 mb-3 mb-md-0">
              <h1 class="m-0 text-dark ttU">Editar</h1>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->

      <section class="content">
        <div class="container-fluid">
          <form action="" class="form">
            <div class="inputs">
              <div class="form-group">
                <label for="exampleInputPassword1">Pregunta</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="titulo"
                  name="question"
                  v-model="faqD.question"
                />
              </div>
              <div class="summ">
                <div
                  id="summernotea"
                  class="summernotea"
                  v-html="faqD.answer"
                ></div>
              </div>

              <button
                type="button"
                class="button btn btn-default btn-default-invert"
                @click="saveData()"
              >
                Guardar
              </button>
            </div>
          </form>
        </div>
        <!-- /.container-fluid -->
      </section>
      <!-- /.content -->
    </div>
  </LayoutDefault>
</template>
<script>
import LayoutDefault from "../layout/LayoutAdmin";
import Loading from "../components/Loading";
import faqsService from "@/services/faqsService";
import config from "@/config/config";
import Toast from "../components/Toast";
import $ from "jquery";

export default {
  name: `FaqsEdit`,
  data() {
    return {
      faqD: { answer: "", question: "" },
      config: config.getConfig(),
      loading: true,
      previewImage: "",
      toastType: "",
      toastActive: false,
      toastMessage: "",
    };
  },
  async beforeMount() {
    this.loading = true;
    if (this.$route.params.faq_id) {
      await this.getFaqFull(this.$route.params.faq_id);
    } else {
      this.$router.push("/dashboard/faqs");
    }
  },
  methods: {
    async saveData() {
      this.loading = true;
      const data = { ...this.faqD };
      data.answer = $("#summernotea").summernote("code");
      const res = await faqsService.updateFaq(data.id, data);

      if (res.error || (res.code && res.code !== 200)) {
        this.toastType = "error";
        this.toastMessage = "Fallo al guardar";
        this.toastActive = true;
        return;
      }

      this.toastType = "success";
      this.toastMessage = "Guardado correctamente";
      this.toastActive = true;
      this.loading = false;
    },

    async getFaqFull(id) {
      const res = await faqsService.getFaq(id);

      if (res.error || (res.code && res.code !== 200)) {
        this.$router.push("/dashboard/faqs");
        this.loading = false;
        return;
      }

      if (res && res.response) {
        this.faqD = { ...res.response };
        setTimeout(() => {
          $("#summernotea").summernote();
        },);
      }
      this.loading = false;
    }, // 1
  },
  components: {
    LayoutDefault,
    Loading,
    Toast,
  },
};
</script>
<style lang="scss">
.btn-edit {
  position: absolute;
  right: 0.5em;
  bottom: 0.5em;
}
.form {
  display: flex;
  align-items: flex-start;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: space-around;
  .inputs {
    width: 40%;
    .button {
      margin-top: 1em;
    }
  }
  .image {
    width: 40%;
    .button {
      margin-top: 1em;
    }
    img {
      width: 400px;
      height: 400px;
    }
  }
  &-group {
    width: 100%;
    position: relative;
  }
  &-control {
    padding: 1em;
    border-radius: 8px;
    resize: none;
    height: inherit;
    &:focus {
      border-radius: 8px;
    }
  }
}
</style>
